import { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios'
import config from '../../config.json'
import { Fragment } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment';
import { toast } from 'react-toastify';
import './style.scss'
import { PermissionWrapper, permission } from '../../components/PermissionWrapper'

export default function EntityAvailabiltyDialog(props) {
  const [open, setOpen] = useState(false);
  const [availability, setAvailability] = useState({})
  const [newValues, setNewValues] = useState({})
  let availabilityRef = useRef({})
  let newValuesRef = useRef({})

  const setDayEntityParam = async (params) => {
    try {
      let body = { ...params }
      const response = await axios.post(`${config.apiBaseUrl}api/v1/manager/dayEntityParam`, body);
      //console.log(response)
      if(response.data.success) {
        toast.success("Sikeresen mentve!")
      } else {
        toast.error("Sikertelen mentés!")
      }
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    newValuesRef.current = newValues
  }, [newValues])

  useEffect(() => {
    availabilityRef.current = availability
  }, [availability])

  const onNewValueChange = async (e) => {
    newValuesRef.current[e.target.name] = e.target.value
    setNewValues({...newValuesRef.current})
  }

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 200,
      renderCell: (params) => (
        <strong>
          {params.value}
        </strong>
      ),
    },
    {
      field: 'name',
      headerName: 'Entitás',
      width: 200,
      renderCell: (params) => (
        <strong>
          {params.value}
        </strong>
      ),
    },    
    {
      field: 'currentValue',
      headerName: 'Jelenlegi érték',
      width: 200,
      renderCell: (params) => (
        <strong>
          {availability[params.row.paramName]}
        </strong>
      ),
    },
    {
      field: 'newValue',
      headerName: 'Új érték',
      width: 200,
      renderCell: (params) => (
        <TextField type='number' name={params.row.paramName} value={newValues[params.row.paramName] || ''} InputLabelProps={{ shrink: true }} onChange={onNewValueChange} />
      ),
    },
    {
      field: 'save',
      headerName: '',
      width: 200,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => setDayEntityParam({
            entityID: availability.id,
            date: moment(props.date).format('YYYY-MM-DD'),
            paramName: params.row.paramName,
            value: newValuesRef.current[params.row.paramName]
          })}
        >
          Mentés
        </Button>
      ),
    }


    
  ];
  const params = [
    {
      id: 1,
      paramName: 'capacityMax',
      name: 'Kapacitás'
    }
  ]

  useEffect(() => {
    if (props.availability == null) return
    setAvailability(props.availability || {})
  }, [props.availability])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PermissionWrapper permissions={[permission.EntitySales]} onlyAdmin={false} locationID={props.location} noPermission={<p></p>}>
      <Fragment>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {props.label}
        </Button>
        <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">A termék napi paraméterei állítása</DialogTitle>
            <DialogContent>
              <div className="data-grid-container">
                <DataGrid rows={params || []} columns={columns} hideFooterPagination={true} hideFooter={true} rowHeight={50}/>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="contained" color="secondary">
                Vissza
              </Button>
            </DialogActions>
        </Dialog>
      </Fragment>
    </PermissionWrapper>
  );
}
