import React, { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'
import Button from '@material-ui/core/Button'

export default function EntityBox(props) {

  const [selectedEntities, setSelectedEntities] = useState([])

  useEffect(() => {
    props.onEntitySelected(selectedEntities)
    // eslint-disable-next-line
  }, [selectedEntities])

  const isEntitySelected = (selectedEntity) => {

    let arrayContainsEntity = false

    for(let entity of selectedEntities) {
      if(entity.id === selectedEntity.id) arrayContainsEntity = true
    }

    return arrayContainsEntity
  }

  const selectEntity = (selectedEntity) => {    

    if(isEntitySelected(selectedEntity)) {
      let newArray = selectedEntities.filter(entity => entity.id !== selectedEntity.id)
      setSelectedEntities(newArray)
    } else {
      let newArray = cloneDeep(selectedEntities)
      newArray.push(selectedEntity)
      setSelectedEntities(newArray)
    }
  }

  const selecteAllEntities = () => {

    setSelectedEntities(Object.values(props.entities))    
  }

  const unSelectAllEntities = () => {
    setSelectedEntities([])
  }

  console.log(props.entities)
  
  return (
    <div className="entity-box-container">
      <div className="entity-box-actions">
        <Button variant="contained" color="primary" onClick={selecteAllEntities}>Mind</Button>
        <Button variant="contained" color="primary" onClick={unSelectAllEntities}>Egyik sem</Button>
      </div>
      <div className="entity-box-buttons">
        {Object.values(props.entities).map((entity, index) =>
          <div key={index} onClick={() => {selectEntity(entity)}} className={`entity-button ${isEntitySelected(entity) ? 'selected' : 'not-selected'}`}>{entity?.name?.hu}</div>
        )}
      </div>
    </div>
  )
}