import { React, useContext } from 'react';
import { EditorContext } from "../../../contexts/editorContext"
import ListItemEntity from './item'

export default function DeliveriesList(props) {
  const { entities } = useContext(EditorContext);
  return (
    <div className='entities-div'>
        {Object.values(entities).map((entity) => 
            <ListItemEntity index={entity.id} key={entity.id} entity={entity} onAddButtonClick={props.onAddButtonClick}/>
        )}
    </div>
  );
}