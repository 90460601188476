import {useContext} from 'react'
import { DataTableContext} from './DataTableContext'

function Header (props) {
    const {sortOrder, refOfHeader, orderingData} = props
    const {columns, checkbox, dndHandle } =
    useContext(DataTableContext);

    return (
        <thead ref={refOfHeader} className={`data-header`}>
          <tr className={`data-row`}>
            {dndHandle && <th className={`data-cell dnd`}></th>}
            {checkbox && <th className={`data-cell checkbox-cell`}></th>}
            {columns.map((column, index) => {
              return (
                <th
                  key={index}
                  className={`data-cell ${column.name} ${
                    column.options?.className != null
                      ? column.options?.className
                      : ""
                  } ${
                    column.options?.sort != null && column.options?.sort
                      ? "sortable"
                      : ""
                  }`}
                  onClick={() => {
                    orderingData(index);
                  }}
                >
                  {column.label}
                  {column.options?.sort != null && column.options?.sort && (
                    <span
                      className={`sort-icon ${
                        sortOrder[0] === index ? "active" : ""
                      }`}
                    >
                    </span>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
      );
}

export default Header