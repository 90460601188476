import { useState, useEffect, useContext, useRef } from 'react'
import Button from '@material-ui/core/Button'
//import { DataGrid } from '@material-ui/data-grid'
import BookingDialog from '../BookingDialog'
import BookingDeleteDialog from '../BookingDeleteDialog'
import axios from 'axios'
import config from '../../config.json'
import moment from 'moment'
import { CommContext } from '../../contexts/commContext'
import { BookingContext } from '../../contexts/bookingContext'
import './style.scss'
import DateTimePicker from 'react-datetime-picker'
import { PermissionWrapper, permission } from '../../components/PermissionWrapper'
import { toast } from 'react-toastify'
import DataTable from '../../components/DataTable'

async function setBookingArrived(id) {
  try {
    let body = {
      bookingID: id
    }
    const response = await axios.post(`${config.apiBaseUrl}api/v1/manager/bookingArrived`, body);
    if (response.data.success) {
      toast.success("OK!")
    } else {
      toast.error("Sikertelen checkin!")
    }
  } catch (error) {
    console.log(error)
  }
}

async function setBookingLeft(id) {
  try {
    let body = {
      bookingID: id
    }
    const response = await axios.post(`${config.apiBaseUrl}api/v1/manager/bookingLeft`, body);
    if (response.data.success) {
      toast.success("OK!")
    } else {
      toast.error("Sikertelen checkout!")
    }
  } catch (error) {
    console.log(error)
  }
}

/*function formattedTimeSegment(datetime) {
  let hour = moment(datetime).format('HH').padStart(2, '0')
  let minute = (Math.floor(+moment(datetime).format('mm') / 15) * 15 +'').padStart(2, '0')
  return `${hour}:${minute}`
}*/

const columns = [
  {
    name: 'id',
    label: '#',
    width: 60,
    options: {
      className: "head-align-center cell-align-center",
      format: row => {
        return (
          <strong>{row.id}</strong>
      )}
    }
  },
  {
    name: 'bookName',
    label: 'Név',
    width: 250,
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => {
        return (
        <strong>
          <BookingDialog label={row.booking.bookName} booking={row.booking} /> 
        </strong>
      )}
    },
  },
  {
    name: 'bookDate',
    label: 'Idő',
    width: 80,
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => (
      <strong>
        {moment(row.booking.bookDate).format('HH:mm')}
      </strong>
      ),
    }
  },
  {
    name: 'name',
    label: 'Termék',
    width: 200,
    options: {
      format: (row) => (
        <><strong>{row.seats}x</strong><>{" "+row.entityName?.hu}</></>
      ),
    }
  },
  {
    name: 'bookArrived',
    label: 'IN',
    width: 100,
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => (
        <strong>
          {(row.booking.bookArrived == null && moment().isSame(row.booking.bookDate, 'date')) ? <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 0 }}
            onClick={() => setBookingArrived(row.booking.id)}
          >
            Kezdés
          </Button> : row.booking.bookArrived != null ? moment(row.booking.bookArrived).format('HH:mm') : 'N/A'}
        </strong>
      ),
    }
  },
  {
    name: 'bookLeft',
    label: 'OUT',
    width: 100,
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => (
        <strong>
          {(row.booking.bookArrived != null && row.booking.bookLeft == null) ? <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 0 }}
            onClick={() => setBookingLeft(row.booking.id)}
          >OUT</Button> : row.booking.bookLeft != null && moment(row.booking.bookLeft).format('HH:mm')}
        </strong>
      ),
    }
  },
  {
    name: 'paymentAgent',
    label: 'Fiz. mód',
    width: 100,
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => row.booking.paymentAgent,
    }
  },
  {
    name: 'totalPaid',
    label: 'Összesen',
    options: {
      className: "head-align-center",
      format: (row) => (
        <div className="total-paid">
        {parseInt(row.seats*row.seatPrice)+' Ft'}
        </div>
      ),
    }
  },
  {
    name: 'delete',
    label: 'Törlés',
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => (
        <BookingDeleteDialog booking={row.booking} /> 
      ),
    }
  }
];

function reduceBookings (rows) {
  let final = {}
  if(rows == null) return final
  for (let row of rows) {
    final[`booking_${row.id}`] = row
  }
  return final
}

export default function BookingsList() {
  const [bookings, setBookings] = useState({});
  const [entityType, setEntityType] = useState(config?.fixedEntityType || +localStorage.getItem('bookings-entityType') || 300)
  const bookingsRef = useRef({})
  const [selectedDate, setSelectedDate] = useState(config?.fixedDate || new Date())
  const { ioClient, ioConnected } = useContext(CommContext)
  const { location } = useContext(BookingContext)

  useEffect(() => {
    bookingsRef.current = bookings
  }, [bookings])

  useEffect(() => {
    localStorage.setItem('bookings-entityType', entityType)
  }, [entityType])

  useEffect(() => {
    async function getBookings() {
      try {
        let body = {
          fromDate: moment(selectedDate).format('YYYY-MM-DD'),
          toDate: moment(selectedDate).format('YYYY-MM-DD'),
          locationID: location
        }
        const response = await axios.get(`${config.apiBaseUrl}api/v1/manager/bookings`, { params: body });
        setBookings(reduceBookings(response.data.data))
      } catch (error) {
        console.log(error)
      }
    }
    function setBooking(booking) {
      if(moment(booking.booking.bookDate).isSame(selectedDate, 'date')) {
        bookingsRef.current[`booking_${booking.booking.id}`] = booking.booking
        setBookings({...bookingsRef.current})
      }
    }
    getBookings()
    if(ioConnected) {
      ioClient.on('bookingUpdated', setBooking)
    }
  }, [ioConnected, ioClient, selectedDate, location])

  const handleDateChange = (date) => {
    
    //let newDate = moment(date).format('YYYY-MM-DD') +' ' + formattedTimeSegment(moment(date))
    //console.log(newDate)
    setSelectedDate(date)
  }

  const bookingListArray = (bookings, entityType) => {
    let bookingEntities = []
    for(let booking of Object.values(bookings)) {
      if(booking.bookDeleted == null) {
        if(booking.entities != null) {
          for(let entity of booking.entities) {
            if(entity.entityType === entityType) {
              bookingEntities.push({ ...entity, booking })
            }
          }
        }
      }
    }
    return bookingEntities
  }

  return (
    <PermissionWrapper permissions={[permission.EntitySales, permission.EventSales]} onlyAdmin={false} locationID={location} noPermission={<p>Nincs jogosultságod...</p>}>
      <div className="booking-list-container">
        {/*<h1>Foglalások - {moment(selectedDate).format('MM/DD') +' - ' + formattedTimeSegment(moment(selectedDate))}</h1>*/}

        <div className="entity-type-select">
          {config?.fixedDate == null && <>
            <DateTimePicker 
              value={selectedDate}
              onChange={(date) => handleDateChange(date)}
              format={"y-MM-dd HH:mm"}
            />
            <PermissionWrapper permissions={[permission.EntitySales]} onlyAdmin={false} locationID={location} noPermission={<p></p>}>
              <div className={`btn product-button ${entityType === 200 ? 'selected' : ''}`} onClick={() => {setEntityType(200)}}>termékek</div>
            </PermissionWrapper>
            <PermissionWrapper permissions={[permission.EventSales]} onlyAdmin={false} locationID={location} noPermission={<p></p>}>
              <div className={`btn event-button ${entityType === 300 ? 'selected' : ''}`} onClick={() => {setEntityType(300)}}>események</div>
            </PermissionWrapper>
          </>}
        </div>
          {/* <DataGrid rows={bookingListArray(bookings, entityType)} columns={columns} hideFooterPagination={true} hideFooter={true} rowHeight={55} /> */}
        <div className="table-container" style={{height: "calc(100% - 72px)", padding: "0 1em 1em"}}>
          <DataTable data={bookingListArray(bookings, entityType)} columns={columns} />
        </div>
      </div>
    </PermissionWrapper>
  );
}
