import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import './style.scss'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useLocation } from "react-router-dom"

function ReportsNavigator(props) {
    const [value, setValue] = useState(0)
    let location = useLocation()

    useEffect(() => {
        switch(location.pathname) {
            case '/reports/eventBooking': setValue(0)
            break
            case '/reports/entityBooking': setValue(1)
            break
            default: setValue(0)
        }
    }, [location]);

    const handleTabSelect = (tabIndex) => {
        switch(tabIndex) {
            case 0: props.history.replace('/reports/eventBooking')
            break
            case 1: props.history.replace('/reports/entityBooking')
            break
            default: props.history.replace('/reports/eventBooking')
        }
    }

    const handleChange = (event, newValue) => {
        handleTabSelect(newValue)
        setValue(newValue)
    }

    return(
        <div className="nav-container-reports">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs">
                <Tab label="Esemény" />
                <Tab label="Termék" />
            </Tabs>
        </div>
    )
}

export default withRouter(ReportsNavigator)