import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Fragment } from 'react';
import './style.scss'

export default function DownloadDialog(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(props.open) handleClickOpen()
    else handleClose()
    // eslint-disable-next-line
  }, [props.open])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if(typeof props.onClose === 'function') {
      props.onClose()
    }
  };

  return (
    <Fragment>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Riport elkészült!</DialogTitle>
        <div className="form-container">
            <DialogContent>
              <a href={`https://download.okeoke.io/reports/${props.fileName}`} download target="new">Letöltés</a>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" type="submit">OK</Button>
              <Button onClick={handleClose} variant="contained" color="secondary">
                Cancel
              </Button>
            </DialogActions>
        </div>
      </Dialog>
    </Fragment>
  );
}
