import React, {useRef, useEffect, useContext} from "react";
import { DataTableContext} from "./DataTableContext";

function Row(props) {
  const { rowIndex /*, moveRow */} = props;
  const { setRowInRefMatrix, setRefOfRows, /*dndHandle,*/ pagination, paginationFromProp, columns } = useContext(DataTableContext)
  const dropRef = useRef(null);
  //const dragRef = useRef(null);

  useEffect(() => {
    setRefOfRows(rowIndex,dropRef)
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    if(rowIndex < pagination || paginationFromProp == null)
    setRowInRefMatrix(rowIndex, {"rowRef":dropRef})
    // eslint-disable-next-line
  }, [pagination, columns])

  return (
    <tr ref={dropRef} key={rowIndex} className={`data-row`} /*style={{ opacity }}*/>
      {props.children}
    </tr>
  );
}
export default Row;
