import { useState, useEffect, useContext } from "react"
import config from '../../../../config.json'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { LoginContext } from "../../../../contexts/loginContext"
import { BookingContext } from "../../../../contexts/bookingContext"
import axios from 'axios'

export default function EntitySelector(props) {

  const [entities, setEntities] = useState([])
  const [entity, setEntity] = useState(0)
  const { loggedIn } = useContext(LoginContext)
  const { location } = useContext(BookingContext)

  const onEntitySelected = (entity1) => {
    setEntity(entity1.target.value)
    if(typeof props.onEntitySelected === 'function') {
      props.onEntitySelected(entities[`entity_${entity1.target.value}`] || {})
    } else {
      //console.log(entity1.target.value)
    }
  }

  useEffect(() => {
    function reduceEntities (rows) {
      let final = {}
      for (let row of rows) {
        final[`entity_${row.id}`] = row
      }
      return final
    }

    async function getEntities() {
      try {
        let body = {
          entityType: 300,
          returnNextOpenHours: true,
          locationID: location
        }
        const response = await axios.get(`${config.apiBaseUrl}api/v1/entities/list`, { params: body });
        setEntities(reduceEntities(response.data.data))
      } catch (error) {
        console.log(error)
      }
    }
    getEntities()
  }, [setEntities, loggedIn, location])

  if(!loggedIn) return null

  return <Select
    readOnly={Object.values(entities).length === 1}
    labelId="entitySelect"
    id="entitySelect"
    value={ entity }
    onChange={onEntitySelected}
  >
    <MenuItem key={0} value={0} selected={true}>Nincs</MenuItem>
    {Object.values(entities).map((entity, index) => 
      <MenuItem key={index} value={entity.id} >{entity.name.hu}</MenuItem>
    )}
  </Select>
}