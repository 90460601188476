import { useState, useEffect } from 'react';
//import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios'
import config from '../../config.json'
import { Fragment } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import './style.scss'

const columns = [
  {
    field: 'name',
    headerName: 'Termék',
    width: 400,
    renderCell: (params) => (
      <strong>
        {params.value?.hu}
      </strong>
    ),
  },
  {
    field: 'seats',
    headerName: 'Mennyiség',
    width: 150,
    renderCell: (params) => (
      <strong>
        {params.value}
      </strong>
    ),
  }
];

export default function BookingDialog(props) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [duration, setDuration] = useState('')
  const [guests, setGuests] = useState(0)
  const [phone, setPhone] = useState('')
  const [entities, setEntities] = useState([])

  useEffect(() => {
    if (props.booking == null) return
    setName(props.booking.bookName || '')
    setDescription(props.booking.bookDescription || '')
    setDuration(props.booking.bookDuration || 0)
    setGuests(props.booking.bookGuests || 0)
    setPhone(props.booking.bookPhone || '')
    setEntities(props.booking.entities || [])
  }, [props.booking])

  /*const updateEntityAvailability = (data) => {
    console.log(data)
  }*/

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitBooking = async () => {
    try {
      let body = {
        booking: {
          "id": props?.booking?.id,
          "bookName": name,
          "bookDescription": description,
          "bookDuration": duration,
          "bookGuests": guests,
          "bookPhone": phone,
        }
      }
      const response = await axios.post(`${config.apiBaseUrl}api/v1/manager/setBooking`, body);
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    submitBooking().then((res) => {
      if (res.success) {
        setOpen(false);
      } else {
        alert('Error saving booking')
      }
    }).catch((e) => {
      console.error(e);
    })
  }

  return (
    <Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {props.label}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Foglalás adatok</DialogTitle>
        <div className="form-container">
          <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
            <DialogContent>
                <div className="data-grid-container">
                  <DataGrid rows={entities || []} columns={columns} />
                </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" type="submit">OK</Button>
              <Button onClick={handleClose} variant="contained" color="secondary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    </Fragment>
  );
}
