import React from 'react'
import './style.scss'

import { EditorProvider } from "./contexts/editorContext"
import { BookingProvider } from "./contexts/bookingContext"
import { LoginProvider } from './contexts/loginContext'
import { CommProvider } from './contexts/commContext'

import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Navigator from './components/Navigator'

import FabricEditor from './BookingManager/FabricEditor'
import FabricView from './BookingManager/FabricView'
import BookingsList from './BookingManager/BookingsList'
import EntityAvailability from './BookingManager/EntityAvailability'
import EntityList from './BookingManager/Entities/EntityList'
import Reports from './BookingManager/Reports'
import Login from './LoginHandler'
import SocketHandler from './SocketHandler'

const App = () => {
  axios.interceptors.request.use(config => {
    config.headers['Accept'] = 'application/json'
    config.headers['Content-Type'] = 'application/json'
    config.headers['Authorization'] = 'Bearer ' + localStorage['apiToken']
    return config
  })
  return (
    <LoginProvider>
      <CommProvider>
        <EditorProvider>
          <BookingProvider>
            <Router>
              <SocketHandler />
              <Navigator />
              <Login />
              <ToastContainer toastClassName="custom-toast" autoClose={1500} />
              <div className='main'>
                <Switch>
                  <Route path="/bookings/list" component={BookingsList} />
                  <Route path="/bookings/entities" component={EntityAvailability} />
                  <Route path="/fabricEditor" component={FabricEditor} />
                  <Route path="/fabricView" component={FabricView} />
                  <Route path="/entities" component={EntityList} />
                  <Route path="/reports" component={Reports} />
                </Switch>
              </div>
            </Router>
          </BookingProvider>
        </EditorProvider>
      </CommProvider>
    </LoginProvider>
  );
}

export default App;
