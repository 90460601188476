import { useRef, useEffect, useContext} from "react";
import { DataTableContext} from "./DataTableContext";

function Cell(props) {

    const {cell, cellIndex, rowIndex} = props
    const {setCellInRefMatrix, pagination, paginationFromProp, columns} = useContext(DataTableContext)
    const ref = useRef(null)

    useEffect (() => {
        if(rowIndex < pagination || paginationFromProp == null)
        setCellInRefMatrix(rowIndex,{[cell.name]:ref })
        // eslint-disable-next-line
    },[pagination, columns])

  return (
    <td
      key={cellIndex}
      ref={ref}
      className={`data-cell ${cell.name} ${
        cell.options?.className != null ? cell.options?.className : ""
      }`}
    >
      {props.children}
    </td>
  );
}

export default Cell;
