import { useState, createContext, useMemo } from 'react';

const EditorContext = createContext(); 

const EditorProvider = (props) => {
    const [entities, setEntities] = useState({})
    const [locations, setLocations] = useState({})
    const [layouts, setLayouts] = useState({})
    const [layout, setLayout] = useState(0)
    const [location, setLocation] = useState(0)
    const [bookDate, setBookDate] = useState(null)
    const [bookAvailability, setBookAvailability] = useState({})

    const value = useMemo(() => ({entities, setEntities}),[entities])
    const value2 = useMemo(() => ({locations, setLocations}),[locations])
    const value3 = useMemo(() => ({layouts, setLayouts}),[layouts])
    const value4 = useMemo(() => ({layout, setLayout}),[layout])
    const value5 = useMemo(() => ({location, setLocation}),[location])
    const value6 = useMemo(() => ({bookDate, setBookDate}),[bookDate])
    const value7 = useMemo(() => ({bookAvailability, setBookAvailability}),[bookAvailability])

    return (
        <EditorContext.Provider
            value={{...value, ...value2, ...value3, ...value4, ...value5, ...value6, ...value7}}
        >
            {props.children}
        </EditorContext.Provider>
    );
}

export { EditorContext, EditorProvider };