import { useState, useEffect, useContext, Fragment } from 'react'
import config from '../../config.json'

import { LoginContext } from '../../contexts/loginContext'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import axios from 'axios'
import { toast } from 'react-toastify'

export default function LoginDialog(props) {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState(localStorage.getItem('username') || '')
  const [pincode, setPincode] = useState('')
  const [stage, setStage] = useState('request')
  const { setLoggedIn, setUser } = useContext(LoginContext)

  const handleClickOpen = () => {
    setOpen(true);
    setUsername(localStorage.getItem('username'))
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    localStorage.setItem('username', username)
  }, [username])

  useEffect(() => {
    localStorage.setItem('pinCode', pincode)
  }, [pincode])

  const submitRequest = async () => {
    try {
      let body = {
        username: localStorage.getItem('username')
      }
      const response = await axios.post(`${config.apiBaseUrl}api/v1/login/requestPinCode`, body);
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const submitLogin = async () => {
    try {
      let body = {
        username: localStorage.getItem('username'),
        pinCode: localStorage.getItem('pinCode')
      }
      const response = await axios.post(`${config.apiBaseUrl}api/v1/login`, body);
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const handleRequest = (event) => {
    event.preventDefault()
    submitRequest().then((res) => {
      if (res.success) {
        toast.success("Sikeres pin kód kérés!")
        setStage('login');
      } else {
        toast.error("Sikertelen pin kód kérés!")
      }
    }).catch((e) => {
      console.error(e);
    })
  }

  const handleLogin = (event) => {
    event.preventDefault()
    submitLogin().then((res) => {
      if (res.success) {
        toast.success("Sikeres belépés!")
        setUser(res.data.user)
        setLoggedIn(true)
        localStorage.setItem('apiToken', res.data.token)
        localStorage.removeItem('username')
        localStorage.removeItem('pinCode')
      } else {
        toast.error("Sikertelen belépés!")
      }
    }).catch((e) => {
      console.error(e);
    })
  }

  const onUsernameChange = (e) => {
    setUsername(e.target.value)
    setStage('request')
  }

  const onPincodeChange = (e) => {
    setPincode(e.target.value)
  }

  return (
    <Fragment>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        {props.label}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        {stage === 'request' && <form autoComplete="off" onSubmit={(e) => handleRequest(e)}>
          <DialogContent>
              <div>
                <TextField label='Email cím' value={username} onChange={onUsernameChange} />
                
              </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">Pinkód kérése</Button>
            <Button onClick={handleClose} color="primary">
              Mégsem
            </Button>
          </DialogActions>
        </form>
        }
        {stage === 'login' && <form autoComplete="off" onSubmit={(e) => handleLogin(e)}>
          <DialogContent>
              <div>
                <TextField label='Email cím' value={username} onChange={onUsernameChange} />
                <TextField label='Pin kód' value={pincode} onChange={onPincodeChange} />
              </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">Belépés!</Button>
            <Button onClick={handleClose} color="primary">
              Mégsem
            </Button>
          </DialogActions>
        </form>
        } 
      </Dialog>
    </Fragment>
  );
}
