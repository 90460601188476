import { useState, useRef, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import config from '../../../config.json'
import { Fragment } from 'react'
import moment from 'moment'
import './style.scss'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import { toast } from 'react-toastify'
import cloneDeep from 'lodash.clonedeep'
import DateTimePicker from 'react-datetime-picker'
import EntitySelector from './EntitySelector'
import EventPrices from './EventPrices'

const baseEvent = {
  "event": { 
    "locationID": 1,
    "name": {
      "en": "",
      "hu": ""
    },
    "description": {
      "en": "",
      "hu": ""
    },
    "seatPrice": 0,
    "vat": 27,
    "capacityMax": 9999,
    "parentEntityID": 0,
    "invoiceItems": []
  },
  "date": moment().format("YYYY-MM-DD HH:mm"),
  "duration": 270 
}

export default function AddEventDialog(props) {

  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [eventData, setEventData] = useState(cloneDeep(baseEvent))
  const eventDataRef = useRef(cloneDeep(baseEvent))

  useEffect(() => {
    eventDataRef.current = eventData
    //console.log(eventDataRef.current)
  }, [eventData])

  const handleClickOpen = () => {
    setEventData(cloneDeep(baseEvent))
    setOpen(true)
  }

  const handleClose = () => {
    if(typeof props.onClose === 'function') {
      props.onClose()
    }
    setOpen(false);
  }

  const submitEntity = async () => {
    try {
      const response = await axios.post(`${config.apiBaseUrl}api/v1/admin/event`, eventDataRef.current)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  function nextOpenHours(nextOpenHoursArr) {
    if(nextOpenHoursArr == null) return {
      id: null,
      date: null,
      startHour: null,
      startSegment: null,
      duration: null
    }
    for(let openHours of Object.entries(nextOpenHoursArr)) {
      let openHour = openHours[1]?.openHoursArray[0]
      return {
        id: openHours.id,
        date: moment(openHours[0]).format('YYYY-MM-DD'),
        startHour: openHour.startHour,
        startSegment: openHour.startSegment,
        duration: openHour.duration * 15
      }
    }
    return {
      id: null,
      date: null,
      startHour: null,
      startSegment: null,
      duration: null
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    submitEntity().then((res) => {
      if (res.success) {
        toast.success("Esemény sikeresen létrejött!")
        handleClose()
      } else {
        toast.error("Hiba történt!")
      }
    }).catch((e) => {
      toast.error("Hiba történt!")
      console.error(e)
    })
  }

  const handleChange = (field, e) => {
    let newEventData = cloneDeep(eventData)
    let fieldArray = field.split('-')

    if(fieldArray[0] === 'event') {

      if(fieldArray[1] === 'name') {
        newEventData.event.name[fieldArray[2]] = e.target.value
      }
      if(fieldArray[1] === 'description') {
        newEventData.event.description[fieldArray[2]] = e.target.value
      }
      if(fieldArray[1] === 'seatprice') {
        newEventData.event.seatPrice = parseInt(e.target.value)
      }
      if(fieldArray[1] === 'capacity') {
        newEventData.event.capacityMax = parseInt(e.target.value)
      }
      if(fieldArray[1] === 'parentEntityID') {
        newEventData.event.parentEntityID = parseInt(e.id)
        let noh = nextOpenHours(e.nextOpenHours)
        if(noh.date != null) {
          handleDateChange(moment(`${noh.date} ${noh.startHour}:${noh.startSegment}`).format())
          newEventData.date = moment(`${noh.date} ${noh.startHour}:${noh.startSegment}`).format()
          newEventData.duration = parseInt(noh.duration)
        }
      }
    }
    if(fieldArray[0] === 'duration') {
      newEventData.duration = parseInt(e.target.value)
    }
    setEventData(newEventData)
  }

  const handleDateChange = (date) => {
    let newEventData = cloneDeep(eventData)
    newEventData.date = moment(date).format("YYYY-MM-DD HH:mm")
    setEventData(newEventData)
    setSelectedDate(new Date(date))
  }

  const invoiceItemsUpdated = (items) => {
    console.log(items)
    let newEventData = cloneDeep(eventData)
    newEventData.event.invoiceItems = items
    if(items.length > 0) {
      let price = 0
      for(let itm of items) {
        price += +itm.price
      }
      newEventData.event.seatPrice = price
    }
    setEventData(newEventData)
  }

  return (
    <Fragment>
      <div className="btn" onClick={handleClickOpen}>Új esemény</div>
      <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Esemény adatok</DialogTitle>
        <div className="add-event-dialog-container">
          <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
            <DialogContent>
                <div className="form-container">
                  <InputLabel>Szülő esemény:</InputLabel>
                  <EntitySelector onEntitySelected={(entity) => handleChange('event-parentEntityID', entity)} />
                  <TextField name="event-name-hu" label="Név (magyar)" variant="outlined" onChange={(e) => {handleChange('event-name-hu', e)}} required/>
                  <TextField name="event-name-en" label="Név (angol)" variant="outlined" onChange={(e) => {handleChange('event-name-en', e)}} required/>
                  <TextField name="event-description-hu" label="Leírás (magyar)" variant="outlined" onChange={(e) => {handleChange('event-description-hu', e)}}/>
                  <TextField name="event-description-en" label="Leírás (angol)" variant="outlined" onChange={(e) => {handleChange('event-description-en', e)}}/>
                  <TextField value={eventData.event.seatPrice} disabled={eventData.event.invoiceItems.length>0} name="event-seatprice" label="Ár" variant="outlined" type="number" onChange={(e) => {handleChange('event-seatprice', e)}} required/>
                  <EventPrices onChange={(items) => invoiceItemsUpdated(items)} />
                  <TextField name="event-capacity" label="Férőhelyek" variant="outlined" type="number" onChange={(e) => {handleChange('event-capacity', e)}} required/>
                  <InputLabel>Mikor kezdődik? (Negyedórás kerekítéssel)</InputLabel>
                  <DateTimePicker 
                    value={selectedDate}
                    onChange={(date) => handleDateChange(date)}
                    format={"y-MM-dd HH:mm"}
                  />
                  <TextField InputLabelProps={{ shrink: true }} value={eventData.duration} name="duration" label="Időtartam percben megadva (Negyedórás kerekítéssel)" variant="outlined" type="number" onChange={(e) => {handleChange('duration', e)}} required/>
                </div>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" type="submit">Rendben</Button>
              <Button onClick={handleClose} variant="contained" color="secondary">Mégsem</Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    </Fragment>
  )
}
