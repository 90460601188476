import { useState, useEffect, useContext } from 'react'
import { LoginContext } from '../../contexts/loginContext'

const permission = {
  EntitySales: 'entitySales',
  EventSales: 'eventSales',
  EventEditor: 'eventEditor'
}

const PermissionWrapper = (props) => {
  const { loggedIn, user } = useContext(LoginContext)
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    function checkPermission () {
      if(!loggedIn) return false
      if(user.isAdmin) return true
      for(let location of user.locations) {
        // eslint-disable-next-line
        if(location.locationID == props.locationID) {
          if(location.isAdmin) return true
          if(location.isManager && !props.onlyAdmin) {
            if(props.permissions == null || props.permissions.length === 0) return true
            for(let perm of props.permissions) {
              if(Array.isArray(location.roles) && location.roles.includes(perm)) return true
            }
            return false
          }
        }
      }
      return false
    }
    setHasPermission(checkPermission())
    // eslint-disable-next-line
  }, [props.permissions, props.locationID, props.onlyAdmin])

  return hasPermission ? (
    props.children
  ) : (
    props.noPermission !== undefined ? props.noPermission : 'No permissions'
  );
};

export { permission, PermissionWrapper } 