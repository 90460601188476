import { useState, Fragment } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { toast } from 'react-toastify'

export default function InvoiceItemDialog(props) {
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState({ name: '', vat: 27, price: '' })

  const handleClickOpen = () => {
    if(props.item == null) {
      setItem({ name: '', vat: 27, price: '' })
    }
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    if(item.name.length < 1 || item.price.length < 1) {
      toast.error('Minden mező kötelező!')
      return
    } else {
      toast.info('Hozzáadva')
    }
    if(typeof props.onSubmit === 'function') {
      props.onSubmit(item)
    }
    setOpen(false)
  }

  const handleChange = (event) => {
    let newItem = {...item}
    newItem[event.target.name] = event.target.value
    setItem(newItem)
  }

  return (
    <Fragment>
      <Button className="entity-name" variant="outlined" color="primary" onClick={handleClickOpen}>
        {props.label}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Új számla tétel</DialogTitle>
        <div className="entity-dialog-container">
          <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
            <DialogContent>
              <TextField required value={item.name} name="name" label="Tétel" variant="outlined" fullWidth={true} onChange={handleChange}/>
              <TextField required value={item.price} type='number' name="price" label="Ár (Bruttó)" variant="outlined" fullWidth={true} onChange={handleChange}/>
              <FormControl variant="outlined">
              <Select name='vat' id="vat" value={item.vat} onChange={handleChange} >
                <MenuItem key={5} value={5}>5%</MenuItem>
                <MenuItem key={18} value={18}>18%</MenuItem>
                <MenuItem key={27} value={27}>27%</MenuItem>
              </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="contained" color="secondary">Mégsem</Button>
              <Button onClick={handleSubmit} variant="contained" color="primary" >Hozzáad</Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    </Fragment>
  )
}
