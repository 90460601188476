import { useState, useEffect, useRef, Fragment } from 'react'

import config from '../../../config.json'
import './style.scss'

import axios from 'axios'
import moment from 'moment'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Switch from '@material-ui/core/Switch'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { cloneDeep } from 'lodash'
import { toast } from 'react-toastify'

const defaultValues = { capacityMax: 0, description: {"hu": "", "en": ""}, name: {"hu": "", "en": ""}, enabled: 1 }

export default function EditEntityDialog(props) {
  const [open, setOpen] = useState(false)  
  const [params, setParams] = useState(cloneDeep(defaultValues))
  const paramsRef = useRef(cloneDeep(defaultValues))

  useEffect(() => {
    paramsRef.current = params
  }, [params])

  const handleClickOpen = () => {
    let newParams = cloneDeep(params)
    newParams.description.hu = (props.uniqueDate?.entityParams != null && props.uniqueDate?.entityParams?.description != null) ? props.uniqueDate.entityParams.description['hu'] : (props?.entity?.description?.hu || '')
    newParams.description.en = (props.uniqueDate?.entityParams != null && props.uniqueDate?.entityParams?.description != null) ? props.uniqueDate.entityParams.description['en'] : (props?.entity?.description?.en || '')
    newParams.name.hu = (props.uniqueDate?.entityParams != null && props.uniqueDate?.entityParams?.name != null) ? props.uniqueDate.entityParams.name['hu'] : (props?.entity?.name?.hu || '')
    newParams.name.en = (props.uniqueDate?.entityParams != null && props.uniqueDate?.entityParams?.name != null) ? props.uniqueDate.entityParams.name['en'] : (props?.entity?.name?.en || '')
    newParams.capacityMax = (props.uniqueDate?.entityParams != null && props.uniqueDate?.entityParams?.capacityMax != null) ? props.uniqueDate.entityParams.capacityMax : (props?.entity?.capacityMax || 0)
    newParams.enabled = (props.uniqueDate?.entityParams != null && props.uniqueDate?.entityParams?.enabled != null) ? props.uniqueDate.entityParams.enabled : (props?.entity?.enabled || 0)
    //console.log(newParams)
    setParams(newParams)
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  const submitEntityParam = async (param) => {
    try {
      let body = {
        entityID: props?.entity.id,
        date: moment(props?.date).format('YYYY-MM-DD'),
        paramName: param.name,
        value: param.value
      }
      const response = await axios.post(`${config.apiBaseUrl}api/v1/manager/dayEntityParam`, body);
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeSwitch = (event) => {
    setParams({ ...paramsRef.current, [event.target.name]: event.target.checked ? 1 : 0 });
  };

  const handleChangeParam = (paramName, e) => {
    let newParams = cloneDeep(params)
    let splitParam = paramName.split('-')
    if(splitParam.length > 1) {
      newParams[splitParam[0]][splitParam[1]] = e.target.value
    }
    if(paramName === 'capacityMax') {
      newParams.capacityMax = e.target.value === "" ? 0 : parseInt(e.target.value)
    }
    setParams(newParams)
  }

  const saveField = (name) =>  saveParam(name, paramsRef.current[name])
  
  const saveParam = (name, value) => {
      let param = {
        name,
        value
      }
      submitEntityParam(param).then((res) => {
      if (res.success) {
        toast.success("Sikeres mentés!")
        setOpen(false)
      } else {
        toast.error("Sikertelen mentés!")
      }
    }).catch((e) => {
      console.error(e);
    })
  }

  return (
    <Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {props.label}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{`${params.name.hu} - ${moment(props?.date).format('YYYY-MM-DD')}`}</DialogTitle>
        <div className="entity-dialog-container">
          <DialogContent>
            <div className="param-container">
              <h3>{`Elérhető?`}
              <Switch
                checked={params.enabled === 1}
                onChange={handleChangeSwitch}
                name="enabled"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              /></h3>
              <Button variant="contained" color="primary" onClick={() => { saveField('enabled') }}>Mentés</Button>
            </div>
            <div className="param-container">
              <h3>{`Esemény neve`}</h3>
              <TextField value={params.name.hu} name="name-hu" label="magyar" variant="outlined" multiline fullWidth={true} onChange={(e) => {handleChangeParam('name-hu', e)}}/>
              <TextField value={params.name.en} name="name-en" label="angol" variant="outlined" multiline fullWidth={true} onChange={(e) => {handleChangeParam('name-en', e)}}/>
              <Button variant="contained" color="primary" onClick={() => { saveField('name') }}>Mentés</Button>
            </div>
            <div className="param-container">
              <h3>{`Leírás`}</h3>
              <TextField value={params.description.hu} name="description-hu" label="magyar" variant="outlined" multiline fullWidth={true} onChange={(e) => {handleChangeParam('description-hu', e)}}/>
              <TextField value={params.description.en} name="description-en" label="angol" variant="outlined" multiline fullWidth={true} onChange={(e) => {handleChangeParam('description-en', e)}}/>
              <Button variant="contained" color="primary" onClick={() => { saveField('description') }}>Mentés</Button>
            </div>
            <div className="param-container">
              <h3>{`Férőhelyek`}</h3>
              <TextField name="capacityMax" label="új érték" variant="outlined" value={params.capacityMax} onChange={(e) => {handleChangeParam('capacityMax', e)}}/>
              <Button variant="contained" color="primary" onClick={() => { saveField('capacityMax') }}>Mentés</Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="secondary">Vissza</Button>
          </DialogActions>
        </div>
      </Dialog>
    </Fragment>
  )
}
