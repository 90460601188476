import { useContext, useEffect } from "react"

import LocationsList from "./list"
import { EditorContext } from "../../../contexts/editorContext"
import axios from 'axios'
import config from '../../../config.json'

function reduceEntities (rows) {
  let final = {}
  for (let row of rows) {
    final[`location_${row.id}`] = row
  }
  return final
}

export default function Locations(props) {
    const { setLocations } = useContext(EditorContext);

    const onLocationSelected = (location) => {
      if(typeof props.onLocationSelected === 'function') {
        props.onLocationSelected (location)
      } else {
        console.log(location)
      }
    }

    useEffect(() => {
        async function getLocations () {
            try {
              let body = {}
              const response = await axios.get(`${config.apiBaseUrl}api/v1/layout/myLocations`, {params: body});
              console.log(response.data.data)
              setLocations(reduceEntities(response.data.data))
            } catch (error) {
              console.log(error)
            }
          }
          getLocations()
    }, [setLocations])
    return <LocationsList onLocationSelected={onLocationSelected} />
}