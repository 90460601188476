import { React, useContext } from 'react';
import { EditorContext } from "../../../contexts/editorContext"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function LayoutsList(props) {
  const { layouts, layout, setLayout } = useContext(EditorContext);

  const handleChange = (event) => {
    setLayout(event.target.value);
  };

  return (
    <Select
          labelId="layoutSelect"
          id="layoutSelect"
          value={layout}
          onChange={handleChange}
        >
          {Object.values(layouts).map((layout) => 
            <MenuItem value={layout.id}>{layout.name}</MenuItem>
        )}
    </Select>
  );
}