import { useState, createContext, useMemo } from 'react'

const LoginContext = createContext()

const LoginProvider = (props) => {
    const [user, setUser] = useState(null)
    const [loggedIn, setLoggedIn] = useState(false)

    const value = useMemo(() => ({user, setUser}),[user])
    const value2 = useMemo(() => ({loggedIn, setLoggedIn}),[loggedIn])

    return (
        <LoginContext.Provider
            value={{...value, ...value2}}
        >
            {props.children}
        </LoginContext.Provider>
    )
}

export { LoginContext, LoginProvider }