import { useEffect, useContext } from 'react'
import './style.scss'
import socketIOClient from 'socket.io-client'
import config from '../config.json'
import { LoginContext } from '../contexts/loginContext'
import { CommContext } from '../contexts/commContext'

export default function SocketHandler() {
  const { loggedIn } = useContext(LoginContext)
  const { setIOConnected, setIOClient, ioClient } = useContext(CommContext)

  useEffect(() => {
    const disconnectIO = () => {
      if (ioClient != null && ioClient.connected) {
        ioClient.disconnect()
      }
    }

    const connectIO = () => {
      if (ioClient != null) {
        ioClient.connect()
        return
      }

      let ioclient = socketIOClient(config.apiBaseUrl, {
        path: '/socket.io',
        transports: ['websocket', 'polling'],
      })

      ioclient.on('connect', () => {
        ioclient.emit('authentication', {
          token: localStorage.getItem('apiToken'),
        })
        setIOConnected(true)
      })

      ioclient.on('disconnect', () => {
        setIOConnected(false)
      })

      setIOClient(ioclient)
    }

    if (!loggedIn) disconnectIO()
    else connectIO()
  }, [loggedIn, setIOConnected, setIOClient, ioClient])

  return loggedIn ? null : <div className="offline-message">Nincs szerver kapcsolat!</div>
}
