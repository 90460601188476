import { useState, useEffect, useContext, useRef } from 'react'
import DataTable from '../../../components/DataTable'
import EntityDialog from '../EntityDialog'
import axios from 'axios'
import config from '../../../config.json'
import { CommContext } from '../../../contexts/commContext'
import { BookingContext } from '../../../contexts/bookingContext'
import './style.scss'
import AddEntityDialog from './AddEventDialog'
import LocalBookingDialog from '../../../components/LocalBookingDialog'
import cloneDeep from 'lodash.clonedeep'
import { PermissionWrapper, permission } from '../../../components/PermissionWrapper'

/*function formattedTimeSegment(datetime) {
  let hour = moment(datetime).format('HH').padStart(2, '0')
  let minute = (Math.floor(+moment(datetime).format('mm') / 15) * 15 +'').padStart(2, '0')
  return `${hour}:${minute}`
}*/

function nextOpenHours(nextOpenHoursArr) {
  if(nextOpenHoursArr == null) return {
    id: null,
    date: null,
    startHour: null,
    startSegment: null
  }
  for(let openHours of Object.entries(nextOpenHoursArr)) {
    let openHour = openHours[1]?.openHoursArray[0]
    let entityParams = openHours[1]?.entityParams
    return {
      id: openHours.id,
      date: openHours[0],
      startHour: openHour.startHour,
      startSegment: openHour.startSegment,
      entityParams
    }
  }
  return {
    id: null,
    date: null,
    startHour: null,
    startSegment: null
  }
}

function reduceEntities (rows) {
  let final = {}
  if(rows == null) return final
  for (let row of rows) {
    final[`entity_${row.id}`] = row
  }
  return final
}

export default function EntityList() {
  const { ioClient, ioConnected } = useContext(CommContext)
  const { location } = useContext(BookingContext)
  const [entities, setEntities] = useState({})
  const [refreshNow, setRefreshNow] = useState(Math.random())
  const [entityType] = useState(localStorage['entitylist-selected-entity-type'] != null ? parseInt(localStorage['entitylist-selected-entity-type']) : 300)

  const entitiesRef = useRef({})

  const columns = [
    {
      name: 'id',
      label: '#',
      options: {
        className: "head-align-center cell-align-center",
        format: false
      }
    },
    {
      name: 'name',
      label: 'Esemény',
      options: {
        className: "head-align-center",
        format: (row) => {
          return (
              <PermissionWrapper permissions={[permission.EventEditor]} onlyAdmin={false} locationID={location} noPermission={<p>{nextOpenHours(row.nextOpenHours)?.entityParams?.name?.hu || (row.name.hu !== "" ? row.name.hu : "N/A")}</p>}>
                <EntityDialog label={nextOpenHours(row.nextOpenHours)?.entityParams?.name?.hu || (row.name.hu !== "" ? row.name.hu : "N/A")} entity={row} />
              </PermissionWrapper>
          )
        },
      }
    },
    {
      name: 'nextOpenHours',
      label: 'Köv. időpont',
      options: {
        className: "head-align-center cell-align-center",
        format: (row) => {
          if(row.name == null) return 'N/A'
          let data = nextOpenHours(row.nextOpenHours)
          return (
            <strong key={data.id}>{data.date} {data.startHour}:{data.startSegment}</strong>
          )
        },
      }
    },
    {
      name: 'entityType',
      label: 'Foglalás',
      options: {
        className: "head-align-center cell-align-center",
        format: (row) => (
          nextOpenHours(row.nextOpenHours).date != null && <LocalBookingDialog label='Foglalás' date={nextOpenHours(row.nextOpenHours).date} entityType={300} />
        ),
      }
    },
    {
      name: 'parentEntityID',
      label: 'Főesemény',
      options: {
        className: "head-align-center cell-align-center",
        format: (row) => {
          return (entitiesRef.current[`entity_${row.parentEntityID}`]?.name?.hu)
        },
      }
    }
  ];
  useEffect(() => {
    entitiesRef.current = entities
  }, [entities])

  useEffect(() => {
  }, [refreshNow])

  function entityAvailabilitUpdated(data) {
    let final = cloneDeep(entitiesRef.current)
    final[`entity_${data.entityID}`] = data.availability.entity
    setEntities(final)
  }

  useEffect(() => {
    async function getEntities() {
      try {
        let body = {
          entityType,
          returnNextOpenHours: true,
          locationID: location
        }
        const response = await axios.get(`${config.apiBaseUrl}api/v1/entities/list`, { params: body });
        setEntities(reduceEntities(response.data.data))
      } catch (error) {
        console.log(error)
      }
    }
    function setEntity(entity) {
      entitiesRef.current[`entity_${entity.entity.id}`] = entity.entity
      setEntities({...entitiesRef.current})
    }
    if(ioConnected) {
      getEntities()
      ioClient.on('entityUpdated', setEntity)
      ioClient.on('entityAvailabilityUpdated', entityAvailabilitUpdated)
    } else {
      ioClient != null && ioClient.off('entityUpdated', setEntity)
      ioClient != null && ioClient.off('entityAvailabilityUpdated', entityAvailabilitUpdated)
    }
  }, [ioConnected, ioClient, entityType, refreshNow, location])

  return (
    <PermissionWrapper permissions={[permission.EventSales]} onlyAdmin={false} locationID={location} noPermission={<p>Nincs jogosultságod!</p>}>
      <div className="entity-list-container">
        <div className="entity-list-header">
          <div className="entity-type-select">
            {/*<div className={`btn product-button ${entityType === 200 ? 'selected' : ''}`} onClick={() => {handleEntityType(200)}}>termékek</div>*/}
            {/* <div className={`btn event-button ${entityType === 300 ? 'selected' : ''}`} onClick={() => {handleEntityType(300)}}>események</div> */}
          </div>
          {entityType === 300 && <AddEntityDialog onClose={() => setRefreshNow(Math.random())} />}
        </div>
        <div className="data-grid-container">
          <DataTable data={Object.values(entities)} columns={columns} />
        </div>
      </div>
    </PermissionWrapper>
  )
  
}
