import { useEffect, useContext } from 'react'
import config from '../config.json'
import './style.scss'

import { LoginContext } from '../contexts/loginContext'

import axios from 'axios'
import Button from '@material-ui/core/Button'
import { toast } from 'react-toastify'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import LoginDialog from '../components/LoginDialog'

async function checkToken () {
    const response = await axios.post(`${config.apiBaseUrl}api/v1/login/refreshToken`, {});
    //console.log(response.data.data.user)
    return response.data
}

export default function LoginHandler () {
    const { loggedIn, setLoggedIn, setUser } = useContext(LoginContext)
    
    function logout () {
        localStorage.clear()
        setLoggedIn(false)
    }

    useEffect(() => {
        checkToken().then((res) => {
            if(res.success) {
                localStorage.setItem('apiToken', res.data.token)
                setUser(res.data.user)
                setLoggedIn(true)
            } else {
                toast.error("Érvénytelen token!")
                setLoggedIn(false)
            }
        }).catch((e) => {
            console.error(e)
        })
    }, [setLoggedIn, setUser])
    if(loggedIn) return <div className="login-container logout" onClick={logout}><Button variant="contained" color="secondary" startIcon={<ExitToAppIcon />}></Button></div>
    return (<div className="login-container login"><LoginDialog label={<ExitToAppIcon />} /></div>)
}