import { useState, useEffect, useContext, Fragment } from 'react'
import config from '../../../config.json'
import './style.scss'
import { CommContext } from '../../../contexts/commContext'

import axios from 'axios'
import moment from 'moment'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DataGrid } from '@material-ui/data-grid'

import EditEntityDialog from '../EditEntityDialog'



function reduceEntities (rows) {
  for (let row of rows) {
    row.id = row.uniqueID
  }
  return rows
}

export default function EventDialog(props) {
  const { ioClient, ioConnected } = useContext(CommContext)
  const [open, setOpen] = useState(false)
  //const [entity, setEntity] = useState('')
  const [uniqueHours, setUniqueHours] = useState([])

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 60,
      renderCell: (params) => {
        return params.value
        }
    },
    {
      field: 'name',
      headerName: 'Esemény',
      width: 400,
      renderCell: (params) => {
        return (<EditEntityDialog 
          label={(params.row.entityParams != null && params.row.entityParams.name != null) ? params.row.entityParams.name.hu : props.entity.name.hu}
          date={params.row.dateAffected}
          entity={props.entity}
          uniqueDate={params.row}
          />)
        }
    },
    {
      field: 'dateAffected',
      headerName: 'Dátum',
      width: 150,
      renderCell: (params) => (
        <strong>
          {moment(params.value).format('YYYY-MM-DD')}
        </strong>
      ),
    },
    {
      field: 'openHoursArray',
      headerName: 'Nyitvatartás',
      width: 200,
      renderCell: (params) => (
          params.value.map((hours, index) => (
            <strong key={index}>{hours.startHour}:{hours.startSegment}-{hours.endHour}:{hours.endSegment}</strong>
          ))
      ),
    }
  ]

  async function getEntityUniqueOpenHours() {
    try {
      let body = {
        entityID: props.entity.id
      }
      const response = await axios.get(`${config.apiBaseUrl}api/v1/admin/entityUniqueOpenHours`, { params: body });
      setUniqueHours(reduceEntities(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(open) {
      if(ioConnected) {
        ioClient.on('entityAvailabilityUpdated', getEntityUniqueOpenHours)
      } else {
        ioClient != null && ioClient.off('entityAvailabilityUpdated', getEntityUniqueOpenHours)
      }
    }
    // eslint-disable-next-line
  }, [open, ioConnected, ioClient])

  const handleClickOpen = () => {
    getEntityUniqueOpenHours()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const submitEntity = async () => {
    try {
      let body = {
        entity: {
          "id": props?.entity?.id,
        }
      }
      const response = await axios.post(`${config.apiBaseUrl}api/v1/admin/setEntity`, body);
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    submitEntity().then((res) => {
      if (res.success) {
        setOpen(false);
      } else {
        alert('Error saving entity')
      }
    }).catch((e) => {
      console.error(e);
    })
  }

  return (
    <Fragment>
      <Button className="entity-name" variant="outlined" color="primary" onClick={handleClickOpen}>
        {props.label}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Esemény adatok</DialogTitle>
        <div className="entity-dialog-container">
          <form autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
            <DialogContent>
                <div className="datagrid-container">
                  <DataGrid rows={uniqueHours || []} columns={columns} />
                </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="contained" color="secondary">Vissza</Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    </Fragment>
  )
}
