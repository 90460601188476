import { useState, useEffect, useContext, useRef } from 'react'
//import { DataGrid } from '@material-ui/data-grid'
import axios from 'axios'
import config from '../../../config.json'
import moment from 'moment'
import { BookingContext } from '../../../contexts/bookingContext'
import './style.scss'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'
import { PermissionWrapper, permission } from '../../../components/PermissionWrapper'
import DownloadDialog from '../../../components/DownloadDialog'
import { toast } from 'react-toastify'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import EntityBox from './EntityBox'
import Button from '@material-ui/core/Button'
import DataTable from '../../../components/DataTable'

const columns = [
  {
    name: 'id',
    label: '#',
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => row.bookingID
    }
  },
  {
    name: 'bookName',
    label: 'Név',
    options: {
      className: "head-align-center",
      format: (row) => row.bookName
    }
  },
  {
    name: 'name',
    label: 'Termék',
    options: {
      className: "head-align-center",
      format: (row) => (
        <>{`${row.seats} x ${row.name?.hu}`}</>
      ),
    }
  },
  {
    name: 'totalPrice',
    label: 'Össz.',
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => (
        <div className="total-price">
        {parseInt(row.totalPrice)+' Ft'}
        </div>
      ),
    }
  },
  {
    name: 'simpleTransactions',
    label: 'simplePay',
    options: {
      className: "head-align-center cell-align-center",
      format: (row) =>  (
        <div className="">
        {row.simpleTransactions}
        </div>
      ),
    }
  },
  {
    name: 'vat5',
    label: 'Áfa 5%',
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => (
        <div className="">{row?.vat5 != null && row.vat5+' Ft'}</div>
      ),
    }
  },
  {
    name: 'vat18',
    label: 'Áfa 18%',
    options: {
      className: "head-align-center cell-align-center",
      format: (row) =>  (
        <div className="">{row?.vat18 != null && row?.vat18+' Ft'}</div>
      ),
    }
  },
  {
    name: 'vat27',
    label: 'Áfa 27%',
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => (
        <div className="">{row?.vat27 != null && row?.vat27+' Ft'}</div>
      ),
    }
  },
  {
    name: 'insertTimestamp',
    label: 'Tranzakció időpontja',
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => (
        <div className="">{row.insertTimestamp}</div>
      ),
    }
  },
  {
    name: 'bookDate',
    label: 'Foglalás időpontja',
    options: {
      className: "head-align-center cell-align-center",
      format: (row) => (
        <div className="">{row.bookDate}</div>
      ),
    }
  },
];

export default function ReportEntityBookings(props) {

  const [report, setReport] = useState([]);
  const [entities, setEntities] = useState([]);
  const [entity, setEntity] = useState([]);
  //const [entityType, setEntityType] = useState(+localStorage.getItem('report-entityType') || '')
  const reportRef = useRef([])
  const { location } = useContext(BookingContext)
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [downloadFilename, setDownloadFilename] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [selectionRange, setSelectionRange] = useState([{ startDate: new Date(),  endDate: new Date(),  key: 'selection' }])

  useEffect(() => {
    reportRef.current = report
  }, [report])

  /*useEffect(() => {
    localStorage.setItem('report-entityType', entityType)
  }, [entityType])*/

  useEffect(() => {
    async function getEntities() {
      try {
        let body = {
          fromDate: moment(selectionRange[0].startDate).format('YYYY-MM-DD'),
          toDate: moment(selectionRange[0].endDate).format('YYYY-MM-DD'),
          locationID: location,
          layoutID: config.layoutID
        }
        let type = props.type === 'entity' ? 'layout' : 'events'
        const response = await axios.get(`${config.apiBaseUrl}api/v1/entities/`+type, { params: body });
        console.log(response.data.data)
        type === 'events' ? setEntities(response.data.data) : setEntities(response.data.data.entities)
      } catch (error) {
        console.log(error)
      }
    }

    getEntities()
    // eslint-disable-next-line
  }, [selectionRange[0].startDate, selectionRange[0].endDate, location, props.type])

  const onEntitySelected = (entities) => {

    let entityIdArray = entities.map(entity => entity.id)
    setEntity(entityIdArray)
  }

  async function getReport(format) {
    try {
      let body = {
        fromDate: moment(selectionRange[0].startDate).format('YYYY-MM-DD 00:00:01'),
        toDate: moment(selectionRange[0].endDate).format('YYYY-MM-DD 23:59:59'),
        locationID: location,
        entities: entity,
        format: format
      }
      const response = await axios.get(`${config.apiBaseUrl}api/v1/admin/reports/entityBookings`, { params: body });
      if(response.data.success) {
        handleCloseDialog()
        if(format === 'csv' || format === 'xlsx') {
          setDownloadFilename(response.data.data.fileName)
          setShowDownloadDialog(true)
        } else {
          console.log(response.data.results)
          setReport(response.data.results)
        }
      } else {
        toast.error("Hiba történt!")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const handleSelectRange = (item) => {
    setSelectionRange([item.selection])
  }

  console.log(props.type)

  return (
    <PermissionWrapper permissions={[permission.EntitySales, permission.EventSales]} onlyAdmin={false} locationID={location} noPermission={<p>Nincs jogosultságod...</p>}>
      <div className="booking-list-container">
        <div className="entity-type-select">
          <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="dialog-title" maxWidth="xl">
            <DialogTitle id="dialog-title">Szűrés</DialogTitle>
            <div className="dialog-content">
              <DateRangePicker
                ranges={selectionRange}
                onChange={handleSelectRange}
                locale={locales['hu']}
              />
              <EntityBox entities={entities} onEntitySelected={onEntitySelected}/>
            </div>
            <div className="action-container">
              <Button variant="contained" color="secondary" onClick={handleCloseDialog} style={{marginRight: '0.5em'}}>Mégsem</Button>
              <Button variant="contained" color="primary" onClick={() => getReport('raw')}>Kérem</Button>
            </div>
          </Dialog>
          {/* <PermissionWrapper permissions={[permission.EntitySales]} onlyAdmin={false} locationID={location} noPermission={<p></p>}>
            <div className={`btn product-button ${entityType === 200 ? 'selected' : ''}`} onClick={() => {setEntityType(200)}}>termékek</div>
          </PermissionWrapper> */}
          <Button variant="contained" color="primary" onClick={handleOpenDialog} style={{marginRight: '0.5em'}}>Új riport</Button>
          <Button variant="contained" color="secondary" onClick={() => getReport('csv')} style={{marginRight: '0.5em'}}>Export csv</Button>
          <Button variant="contained" color="secondary" onClick={() => getReport('xlsx')}>Export xlsx</Button>
          <DownloadDialog fileName={downloadFilename} label={'Letöltés'} open={showDownloadDialog} onClose={() => setShowDownloadDialog(false)} />
        </div>
        <div className="data-grid-container hidden appear">
          <DataTable data={report} columns={columns} />
        </div>
      </div>
    </PermissionWrapper>
  );
}
