import { React, useContext, useEffect } from 'react';
import { EditorContext } from "../../../contexts/editorContext"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function LocationsList(props) {
  const { locations, location, setLocation, setLayouts } = useContext(EditorContext);

  const handleChange = (event) => {
    setLocation(event.target.value);
  };

  useEffect(() => {
    console.log(locations, location)
    if(locations[`location_${location}`] != null) {
      setLayouts(locations[`location_${location}`].layouts)
    }
  }, [locations, location, setLayouts])

  return (
    <Select
          labelId="locationSelect"
          id="locationSelect"
          value={location}
          onChange={handleChange}
        >
          {Object.values(locations).map((location) => 
            <MenuItem value={location.id}>{location.name}</MenuItem>
        )}
    </Select>
  );
}