import { useContext, useEffect } from "react"
import { BookingContext } from "../../contexts/bookingContext"
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

export default function LayoutSelector(props) {
    const {locations, location, layout, setLayout} = useContext(BookingContext);
    
    useEffect(() => {
      const selectFirstLayout = () => {
        if(locations[`location_${location}`] == null) return 0
        for(let lay of Object.values(locations[`location_${location}`].layouts)) {
          return lay.id
        }
        return 0
      }
      let lastLayout = localStorage.getItem(props.name+'-bookingSelectedLayout') || selectFirstLayout()
      setLayout(lastLayout)
      // eslint-disable-next-line
    }, [locations])

    useEffect(() => {
      const selectFirstLayout = () => {
        if(locations[`location_${location}`] == null) return 0
        for(let lay of Object.values(locations[`location_${location}`].layouts)) {
          return lay.id
        }
        return 0
      }
      let lastLayout = selectFirstLayout()
      localStorage.removeItem(props.name+'-bookingSelectedLayout')
      setLayout(lastLayout)
      // eslint-disable-next-line
    }, [location])

    useEffect(() => {
      if(typeof props.setLayout === 'function') {
        props.setLayout (layout)
      }
      localStorage.setItem(props.name+'-bookingSelectedLayout', layout)
      // eslint-disable-next-line
    }, [layout, props.setLayout])

    if(locations[`location_${location}`] == null) return null

    if(Object.values(locations[`location_${location}`].layouts).length < 2) return null

    return (
      <FormControl variant="outlined" >
        <Select
          id="layoutSelect"
          value={layout}
          onChange={(e) => setLayout(e.target.value)}
        >
          {Object.values(locations[`location_${location}`].layouts).map((layout, index) => 
            <MenuItem key={index} value={layout.id}>{layout.name}</MenuItem>
          )}
        </Select>
      </FormControl>
    )
}