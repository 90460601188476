import ReportsNavigator from './Navigator'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import EntityBookings from './EntityBookings'

export default function Reports() {
  return (
    <Router>
      <ReportsNavigator />
        <Switch>
          <Route exact path="/reports">
            <Redirect to="/reports/eventBooking" />
          </Route>
          <Route path="/reports/eventBooking">
            <EntityBookings type={"event"} />
          </Route>
          <Route path="/reports/entityBooking" >
            <EntityBookings type={"entity"} />
          </Route>
        </Switch>
    </Router>
  )
}
