import { useState } from 'react';
//import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios'
import config from '../../config.json'
import { Fragment } from 'react';
import './style.scss'

export default function BookingDialog(props) {
  const [open, setOpen] = useState(false);
 
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(props)

  const deleteBooking = async (reasonCode) => {
    try {
      let body = {
        reasonCode,
        bookingID: props.booking.id
      }
      const response = await axios.post(`${config.apiBaseUrl}api/v1/manager/bookingDeleted`, body);
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  const handleDelete = (reasonCode) => {
    deleteBooking(reasonCode).then((res) => {
      if (res.success) {
        setOpen(false);
      } else {
        alert('Error saving booking')
      }
    }).catch((e) => {
      console.error(e);
    })
  }

  return (
    <Fragment>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Törlés
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Biztos hogy törlöd?</DialogTitle>
        <div className="form-container">
          <form autoComplete="off">
            <DialogContent>
                <div className="data-grid-container">
                  <Button onClick={() => handleDelete(2)} variant="contained" color="primary">
                    Igen, vendég visszamondta
                  </Button><br/><br/>
                  <Button onClick={() => handleDelete(3)} variant="contained" color="primary">
                    Igen, hibás rendelés
                  </Button>
                </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="contained" color="secondary">
                Mégsem
              </Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    </Fragment>
  );
}
