import React, { useState, useEffect } from 'react'

import './plusMinusField.scss'

import {ReactComponent as CircleTrashIconActive} from "../../svg/circleTrashIconActive.svg"
import {ReactComponent as CircleMinusIconActive} from "../../svg/circleMinusIconActive.svg"
import {ReactComponent as CirclePlusIconActive} from "../../svg/circlePlusIconActive.svg"
import {ReactComponent as CirclePlusIconPassive} from "../../svg/circlePlusIconPassive.svg"
import {ReactComponent as CircleMinusIconPassive} from "../../svg/circleMinusIconPassive.svg"

export default function CountButton(props) {
    
    const [count, setCount] = useState(props.count != null ? +props.count : 0)

    const minValue = props.minValue ? +props.minValue : 0
    const maxValue = props.maxValue ? +props.maxValue : 10

    useEffect(() => {
    
        props.count != null ? setCount(props.count) : setCount(0)
    }, [props.count])

    const stateUpdated = (count, preValue) => {
        let diff = count - preValue
        props.onChange({count: count, diff: diff});
    }

    const canSelect = () => {
        if(typeof(props.canSelect) === 'function') return props.canSelect()
        else return 1
    }    

    const canIncrement = () => {
        
        return count < maxValue && canSelect()
    }

    const incrementCount = () => {
        
        if(!canIncrement()) return

        if(count === 0 && props.firstStep != null) {

            setCount(count + props.firstStep)
            stateUpdated(count + props.firstStep, count)
        }

        if(count !== 0) {

            setCount(count + 1)    
            stateUpdated(count + 1, count)
        }

    }  

    const canDecrement = () => {
        return count > minValue && canSelect()
    }

    const decrementCount = () => {

        if(!canDecrement()) return

        if(props.firstStep != null && count === props.firstStep) {

            setCount(0)
            stateUpdated(0, count)    
        }

        if(count !== props.firstStep) {
            setCount(count - 1)
            stateUpdated(count - 1, count)    
         }
    
    }

    const plusImage=<CirclePlusIconPassive />
    const plusActiveImage=<CirclePlusIconActive />
    const minusActiveImage=<CircleMinusIconActive />
    const oneLeftImage=<CircleMinusIconPassive />
    const oneLeftActiveImage=<CircleTrashIconActive />

    let plus
    let minus
    
    minus = canDecrement() ? (count===1 ? oneLeftActiveImage : minusActiveImage) : oneLeftImage
    plus = canIncrement() ? plusActiveImage : plusImage

    if(count !== 0) {
        return (
            <div className="plusMinusField-container">
                <div className={'plusMinusField-minus '+(canDecrement() ? 'active ' : '') +(count===1 ? 'oneLeft' : '')} onClick={decrementCount} >{minus}</div>
                <span className="plusMinusField-count">{count}</span>
                <div className={'plusMinusField-plus '+(canIncrement() ? 'active ' : '')} onClick={incrementCount} >{plus}</div>
            </div>
        )
    } else {
        return (
            <div className="toBasketIcon" onClick={props.addItem}>hozzáad</div>
        )
    }
}