import { React } from 'react';
import ListItem from '@material-ui/core/ListItem';

export default function EntityCard(props) {
  if(props.entity == null) return null
  return (
    <ListItem>
      <div className='entities-list-item' onClick={() => {props.onAddButtonClick(props.entity)}}>
            #{props.entity.id}<br />
            {props.entity.name}<br />
            {/* <IconButton aria-label="addEntity" onClick={() => {props.onAddButtonClick(props.entity)}}>
              <BeachAccessIcon  />
            </IconButton> */}
      </div>
    </ListItem>
  );
}
