import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import InvoiceItemDialog from './InvoiceItemDialog'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function EventPrices(props) {
  const classes = useStyles();
  const [items, setItems] = useState([])

  useEffect(() => {
    if(typeof props.onChange === 'function') {
        props.onChange(items)
    }
    // eslint-disable-next-line
  }, [items])

  useEffect(() => {
    setItems(props.items || [])
  }, [props.items])

  const onAddItem = (item) => {
    let newItems = [...items]
    newItems.push(item)
    setItems(newItems)
  } 

  const removeItem = (index) => {
    let newItems = [...items]
    newItems.splice(index, 1)
    setItems(newItems)
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Tétel</TableCell>
            <TableCell>Ár (Bruttó)</TableCell>
            <TableCell>Áfa csoport</TableCell>
            <TableCell><InvoiceItemDialog label='Új tétel' onSubmit={onAddItem} /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.price} Ft</TableCell>
              <TableCell>{row.vat}%</TableCell>
              <TableCell><DeleteIcon onClick={()=>removeItem(index)} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
