import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Body from "./Body";
import { DataTableContext } from "./DataTableContext";

function DataTable(props) {
  const {
    columns, //stores {showOnScreen as name and label, orderBy as sort, format, type}
    checkbox, //Boolean -> modifies the data when checked, placing a new element. (_checked: 0 || 1)
    data, //[{row_1},{row_2},...,{row_N}]
    setData, //setter for data
    className,
    showScrollBar, //Boolean
    // showRowsFromTo, //[int,int]
    pagination: paginationFromProp, //[int]
    underRowName,
    underRowContent,
    dndHandle, //boolean, drag and drop handler
  } = props;

  const [sortOrder, setSortOrder] = useState(["", false]); //[ key ,ascending = true || descending = false]
  const [showRowsFromTo, setShowRowsFromTo] = useState(false);
  const [pagination, setPagination] = useState(paginationFromProp);

  const refMatrix = useRef({});

  const setRowInRefMatrix = (rowID, rowRef) => {
    refMatrix.current[rowID] = { ...refMatrix.current[rowID], ...rowRef}
  };

  const setCellInRefMatrix = (rowID, cellRef) => {
    if(refMatrix.current[rowID]?.rowRefs){
      refMatrix.current[rowID].rowRefs = { ...refMatrix.current[rowID].rowRefs, ...cellRef };
    }
    else{
       refMatrix.current[rowID] = { ...refMatrix.current[rowID], "rowRefs":cellRef}
    }
  };

  const refOfTable = useRef(null);
  const refOfHeader = useRef(null);
  const refOfRow = useRef(null);
  const refOfPagination = useRef(null);

  const setRefOfRows = (index, ref) => {
    if (index === 0) {
      refOfRow.current = ref.current;
      settingUpPagination();
    }
  };

  // const setRefOfPagination = (ref) => {
  //   refOfPagination.current = ref.current;
  //   settingUpPagination();
  // };

  const settingUpPagination = () => {
    if (refOfRow.current !== null && paginationFromProp === 0) {
      let table_height = refOfTable?.current?.getBoundingClientRect().height;
      let header_height = refOfHeader?.current?.getBoundingClientRect().height;
      let row_height = refOfRow?.current?.getBoundingClientRect().height;
      if (refOfPagination !== null) {
        let pagination_height =
          refOfPagination?.current?.getBoundingClientRect().height;
        setPagination(
          Math.floor(
            (table_height - header_height - pagination_height) / row_height
          )
        );
      }
    }
  };

  //#################### Order
  const orderingData = (columnIndex) => {
    if (!columns[columnIndex]?.options?.sort) {
      return null;
    } else if (typeof columns[columnIndex]?.options?.sort === "function") {
      columns[columnIndex].options.sort(
        columns[columnIndex].name,
        sortOrder[1]
      );
    } else {
      let temp = data;
      let key = columns[columnIndex].name;
      if (sortOrder[1] && sortOrder[0] === columnIndex) {
        temp = temp.sort(function (a, b) {
          if (!b[key]) return -1;
          if (a[key] > b[key]) {
            return -1;
          }
          if (a[key] < b[key]) {
            return 1;
          }
          return 0;
        });
      } else {
        temp = temp.sort(function (a, b) {
          if (!b[key]) return -1;
          if (a[key] > b[key]) {
            return 1;
          }
          if (a[key] < b[key]) {
            return -1;
          }
          return 0;
        });
      }
      if (typeof setData === "function") setData(Object.values({ ...temp }));
    }
    setSortOrder([
      columnIndex,
      columnIndex === sortOrder[0] && sortOrder[1] ? false : true,
    ]);
  };

  useEffect(() => {
    console.log(pagination);
    if (pagination) {
      setShowRowsFromTo([0, pagination]);
    } else {
      setShowRowsFromTo([0, data != null && data.length]);
    }
    // eslint-disable-next-line
  }, [pagination]);

  useEffect(() => {
    if (data && !pagination) {
      setShowRowsFromTo([0, data.length]);
    }
    if (data && pagination) {
      setShowRowsFromTo([0, pagination]);
    }
    // eslint-disable-next-line
  }, [data]);

  const DataTableContextValues = {
    data,
    setData,
    columns,
    checkbox,
    dndHandle,
    refMatrix,
    pagination,
    underRowName,
    showScrollBar,
    underRowContent,
    paginationFromProp,
    setRefOfRows,
    setRowInRefMatrix,
    setCellInRefMatrix,
  };

  if (!data) return null;

  return (
    <DataTableContext.Provider value={DataTableContextValues}>
      <div className="okeoke-data-table">
        <div
          ref={refOfTable}
          onresize={settingUpPagination}
          className={`data-table-inner ${pagination ? "paginated" : ""}`}
        >
          <table className={`data-table ${className || ""}`}>
            {/* {returnThead()} */}
            <Header
              sortOrder={sortOrder}
              refOfHeader={refOfHeader}
              orderingData={orderingData}
            />
            <Body refMatrix={refMatrix} showRowsFromTo={showRowsFromTo} />
          </table>
        </div>
      </div>
    </DataTableContext.Provider>
  );
}

export default DataTable;
