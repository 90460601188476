import { useState, useEffect, useContext, useRef } from 'react'
//import Button from '@material-ui/core/Button'
import DataTable from '../../components/DataTable'
//import Select from '@material-ui/core/Select'
//import MenuItem from '@material-ui/core/MenuItem'
import axios from 'axios'
import config from '../../config.json'
import moment from 'moment'
import merge from 'lodash.merge'
import { CommContext } from '../../contexts/commContext'
import { LoginContext } from '../../contexts/loginContext'
import EntityAvailabiltyDialog from '../EntityAvailabiltyDialog'
import LayoutSelector from '../../components/LayoutSelector'
import { BookingContext } from "../../contexts/bookingContext"
import './style.scss'
import DateTimePicker from 'react-datetime-picker'

import LocalBookingDialog from '../../components/LocalBookingDialog'
import { PermissionWrapper, permission } from '../../components/PermissionWrapper'
/*function getFirstSegment(availability) {

}*/
// BUILD ACCORDING TO PARAMS!!! - OK
function getActiveAvailabilitySegment(row, date) {
  if(row == null) return {}
  let hour = +moment(date).format('HH')
  let minute = (Math.floor(+moment(date).format('mm') / 15) * 15 + '').padStart(2, '0')

  if(row.timeForceFromStartAlways === 1 || (!moment().isSame(date, 'date') && row.timeForceFromStart)) {
    hour = row.firstOpenSegment.hour
    minute = row.firstOpenSegment.minute
  }

  try {
    let res = { ...row.availability[hour].segments[minute], hour, minute }
    return res
  } catch (e) {
    return { hour, minute }
  }
}

/*function formattedTimeSegment(datetime) {
  let hour = moment(datetime).format('HH').padStart(2, '0')
  let minute = (Math.floor(+moment(datetime).format('mm') / 15) * 15 +'').padStart(2, '0')
  return `${hour}:${minute}`
}*/

function digestLayout(layout) {
  for (let ent of Object.values(layout)) {
    merge(ent, ent.entity)
  }
  return layout
}

/*function selectMenuItems(count) {
  let res = []
  for (let i = 0; i <= count; i++) {
    res.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
  }
  return res
}*/

export default function EntityAvailability() {
  const [entities, setEntities] = useState({})
  // eslint-disable-next-line
  const [newEntity] = useState({})
  const entitiesRef = useRef({})
  const newEntityRef = useRef({})
  const { ioClient, ioConnected } = useContext(CommContext)
  const { layout, location } = useContext(BookingContext);
  const layoutRef = useRef(layout)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const selectedDateRef = useRef(new Date())
  const { loggedIn } = useContext(LoginContext)

  /*const handleEntitySelect = (e) => {
    console.log(e)
    newEntityRef.current[`${e.target.name}`] = e.target.value
    setNewEntity({...newEntityRef.current})
  }*/

  const columns = [
    {
      name: 'id',
      label: '#',
      options: {
        className: "head-align-center cell-align-center",
        format: false
      }
    },
    {
      name: 'name',
      label: 'Termék',
      options: {
        className: "head-align-center",
        format: row => {
        return <strong>{row.name.hu} ({getActiveAvailabilitySegment(row, selectedDate)?.hour}:{getActiveAvailabilitySegment(row, selectedDate)?.minute})</strong>
        },
      }
    },
    {
      name: 'capacityMax',
      label: 'Kapacitás',
      options: {
        className: "head-align-center cell-align-center",
        format: row =>  {
          return (
            <PermissionWrapper permissions={[permission.EntitySales]} onlyAdmin={false} locationID={location} noPermission={<p>{row.name.hu}</p>}>
              <EntityAvailabiltyDialog label={row.capacityMax} availability={row} date={selectedDate} location={location} />
            </PermissionWrapper>
          )
        },
      }
    },
    {
      name: 'free',
      label: 'Elérhető',
      options: {
        className: "head-align-center cell-align-center",
        format: row => (
          <strong>
            {getActiveAvailabilitySegment(row, selectedDate)?.availableSeats || '0'}
          </strong>
        ),
      }
    },
    {
      name: 'taken',
      label: 'Foglalt',
      options: {
        className: "head-align-center cell-align-center",
        format: row => (
          <strong>
            {getActiveAvailabilitySegment(row, selectedDate)?.bookedSeats || '0'}
          </strong>
        ),
      }
    },
    {
      name: 'seatPrice',
      label: 'Ár',
      options: {
        className: "head-align-center cell-align-right",
        format: row =>  (
          <strong>
            {parseInt(getActiveAvailabilitySegment(row, selectedDate)?.seatPrice || row.seatPrice)} Ft
          </strong>
        ),
      }
    },
    {
      name: 'id2',
      label: 'Foglalás',
      options: {
        className: "head-align-center cell-align-center",
        format: row => (
          <strong>
            <LocalBookingDialog label='Foglalás' date={selectedDate} entityType={200} />
          </strong>
        ),
      }
    }    
  ]

  useEffect(() => {
    selectedDateRef.current = selectedDate
  }, [selectedDate])

  useEffect(() => {
    layoutRef.current = layout
  }, [layout])

  useEffect(() => {
    entitiesRef.current = entities
  }, [entities])

  useEffect(() => {
    newEntityRef.current = newEntity
  }, [newEntity])

  async function getEntities() {
    if(layout == null) return null
    try {
      let body = {
        date: moment(selectedDateRef.current).format('YYYY-MM-DD'),
        layoutID: config.locationID,
      }
      const response = await axios.get(
        `${config.apiBaseUrl}api/v1/availability/layout`,
        { params: body }
      )
      //console.log(response.data.data)
      setEntities(digestLayout(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
      getEntities()
      // eslint-disable-next-line
  }, [layout, selectedDate])

  useEffect(() => {
    const setEntity = () => {
      getEntities()
    }
    if(ioConnected) {
      getEntities()
      ioClient.on('entityUpdated', setEntity)
      ioClient.on('entityAvailabilityUpdated', setEntity)
    } else {
      ioClient != null && ioClient.off('entityUpdated', setEntity)
      ioClient != null && ioClient.off('entityAvailabilityUpdated', setEntity)
    }
    // eslint-disable-next-line
  }, [ioConnected, ioClient])

  if(loggedIn) {
    return (
      <PermissionWrapper permissions={[permission.EntitySales]} onlyAdmin={false} locationID={location} noPermission={<p>Nincs jogosultságod!</p>}>
        <div className="entity-availability-container">
          <div className="data-filter-container">
            <LayoutSelector name='entityAvailability' />
            <DateTimePicker 
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              format={"y-MM-dd HH:mm"}
            />
          </div>
          <div className="data-grid-container" style={{height: "calc(100% - 48px"}} >
            <DataTable data={Object.values(entities)} columns={columns} />
          </div>
        </div>
      </PermissionWrapper>
    )
  } else {
    return 'Lépj be a folytatáshoz'
  }
}
